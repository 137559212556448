
import { Component, Vue } from "vue-property-decorator";
import { Action } from "vuex-class";

@Component({
  name: "Error500",
  components: {},
})
export default class Error500 extends Vue {
  @Action("clearMenuConfiguration", { namespace: "application" })
  clearMenuConfiguration!: () => void;
  @Action("clearViewContext", { namespace: "application" })
  clearViewContext!: () => void;
  mounted() {
    this.$jfModal.closeAll();
    this.clearMenuConfiguration();
    this.clearViewContext();
  }
}
